import { Layout } from '@components/layout';
import { Contact } from '@components/standard';
import { Portfolio } from '@components/standard';
import React from 'react';

const PortfolioPage = ({ pageContext, location }) => {
    const { page } = pageContext;

    return (
        <Layout {...page}>
            <Portfolio />
            <Contact />
        </Layout>
    );
};

export default PortfolioPage;
